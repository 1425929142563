import {PageProps, Link} from 'gatsby';
import React from 'react';
import Layout from '../../layouts/layout';
import SEO from '../../components/seo';

const NotFoundPage: React.FC<
  PageProps<null>
> = () => (
  <section
    className="md:pl-10 md:border-l-2 h-full md:flex
    justify-start font-gordo p-10 flex-col min-h-screen">
    <SEO title="404: Not found" />
    <h1 className="text-gray-800 mb-3">
      NOT FOUND
    </h1>
    <p className="font-charisma text-gray-600">
      It seems there is nothing here.
    </p>
    <p className="text-4xl">🫤</p>
    <Link to="/">
      <button className="mt-10 text-base border-2 rounded-xl font-charisma py-3 px-5 hover:shadow bg-white">
        Go back
      </button>
    </Link>
  </section>
);

export default NotFoundPage;
